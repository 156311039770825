@import "../../styles/animation";
@import "../../styles/variables";

.home-page {
  overflow-x: hidden;
  cursor: url("../../assets/images/fantasy_mouse.png"), auto;

  .back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #111;
    padding: 5px;
    z-index: 99;
    border-radius: 50px;
    border: 2px solid $gold;
    transition: all 0.25s ease-in-out;
    background-image: url("../../assets/images/up_dark.png");
    height: 60px;
    width: 30px;
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 999;

    img {
      height: 60px;
    }

    &:hover {
      transition: all 0.25s ease-in-out;
      background-color: $gold;
      border: 2px solid #111;
      background-image: url("../../assets/images/up_darkbg.png");
    }
  }

  .banner {
    overflow: hidden;
    height: 100vh;
    width: 101%;
    display: flex;
    position: relative;

    .text-container {
      position: relative;
      z-index: 9;
      margin: auto;

      h1 {
        font-size: 90pt;
        line-height: 115px;
        font-weight: 600;
        text-align: center;
        font-family: "finalFantasy";
        opacity: 0;
        animation: banner-text-animation 2s;
        animation-fill-mode: forwards;
        animation-delay: 2s;
      }
    }

    .birds-front {
      z-index: 6;
      height: 200px;
      position: absolute;
      top: 35vh;
      animation: bird-move-accross 60s infinite linear;
    }

    .birds-back {
      z-index: 6;
      height: 120px;
      position: absolute;
      top: 5vh;
      animation: bird-move-accross-behind 120s infinite linear;
    }

    .landscape {
      position: absolute;
      top: 0;
      left: 0;
      background-position: center center;
      background-size: cover;
      height: 100%;
      width: 100%;
      opacity: 0;
      overflow: hidden;
      animation-timing-function: ease-in-out;
      background-attachment: fixed;

      &.first {
        background-image: url("../../assets/images/blue_background/blue_1_hero.png");
        z-index: 7;
        animation: landscape-animation 3s;
        animation-fill-mode: forwards;
      }

      &.second {
        background-image: url("../../assets/images/blue_background/blue_2.png");
        z-index: 6;
        animation: landscape-moveup-animation 3s;
        animation-delay: 0.6s;
        animation-fill-mode: forwards;
      }

      &.third {
        background-image: url("../../assets/images/blue_background/blue_3.png");
        z-index: 5;
        animation: landscape-moveup-animation 3s;
        animation-delay: 0.8s;
        animation-fill-mode: forwards;
      }

      &.fourth {
        background-image: url("../../assets/images/blue_background/blue_4.png");
        z-index: 4;
        animation: landscape-moveup-animation 3s;
        animation-fill-mode: forwards;
        animation-delay: 1s;
      }

      &.fifth {
        background-image: url("../../assets/images/blue_background/blue_5.png");
        z-index: 3;
        animation: landscape-moveup-animation 3s;
        animation-fill-mode: forwards;
        animation-delay: 1.2s;
      }

      &.sixth {
        background-image: url("../../assets/images/blue_background/blue_6.png");
        z-index: 2;
        animation: landscape-moveup-animation 3s;
        animation-delay: 1.4s;
        animation-fill-mode: forwards;
      }

      &.seventh {
        background-image: url("../../assets/images/blue_background/blue_7.png");
        z-index: 1;
        opacity: 1;
      }

      &.forest-first {
        background-image: url("../../assets/images/sunset_forest/forest_1.png");
        z-index: 7;
        animation: landscape-animation 3s;
        animation-fill-mode: forwards;
        opacity: 1 !important;
      }

      &.forest-second {
        background-image: url("../../assets/images/sunset_forest/forest_2.png");
        z-index: 6;
        animation: landscape-moveup-animation 3s;
        animation-delay: 0.6s;
        animation-fill-mode: forwards;
      }

      &.forest-third {
        background-image: url("../../assets/images/sunset_forest/forest_3.png");
        z-index: 5;
        animation: landscape-moveup-animation 3s;
        animation-delay: 0.8s;
        animation-fill-mode: forwards;
      }

      &.forest-fourth {
        background-image: url("../../assets/images/sunset_forest/forest_4.png");
        z-index: 4;
        animation: landscape-moveup-animation 3s;
        animation-fill-mode: forwards;
        animation-delay: 1s;
      }

      &.forest-fifth {
        background-image: url("../../assets/images/sunset_forest/forest_5.png");
        z-index: 1;
        opacity: 1;
      }

      &.throne-first {
        background-image: url("../../assets/images/throne/Throne_1.png");
        z-index: 6;
        animation: landscape-animation 3s;
        animation-fill-mode: forwards;
      }

      &.throne-second {
        background-image: url("../../assets/images/throne/Throne_2.png");
        z-index: 5;
        animation: landscape-moveup-animation 3s;
        animation-delay: 0.6s;
        animation-fill-mode: forwards;
      }

      &.throne-third {
        background-image: url("../../assets/images/throne/Throne_3.png");
        z-index: 4;
        animation: landscape-moveup-animation 3s;
        animation-delay: 0.8s;
        animation-fill-mode: forwards;
      }

      &.throne-fourth {
        background-image: url("../../assets/images/throne/Throne_4.png");
        z-index: 3;
        animation: landscape-moveup-animation 3s;
        animation-fill-mode: forwards;
        animation-delay: 1s;
      }

      &.throne-fifth {
        background-image: url("../../assets/images/throne/Throne_5.png");
        z-index: 2;
        animation: landscape-moveup-animation 3s;
        animation-delay: 1.4s;
        animation-fill-mode: forwards;
      }

      &.throne-sixth {
        background-image: url("../../assets/images/throne/Throne_6.png");
        z-index: 1;
        opacity: 1;
      }
    }
  }

  .about {
    background-color: #111111;

    a {
      display: initial;
    }
  }

  .project-container {
    display: grid;
    column-gap: 150px;
    grid-template-columns: auto auto;

    .project {
      margin: 30px 0;

      h1 {
        color: $gold;
        font-size: 32pt;
      }

      h2 {
        margin: 15px 0 0 0;
        padding-bottom: 5px;
        font-size: 18pt;
      }

      p,
      li {
        padding: 0;
        text-align: left;
        line-height: 25px;
      }

      .padding-bottom {
        padding-bottom: 10px;
      }

      .gap {
        gap: 15px;
      }
    }
  }

  .student {
    h4 {
      color: #fff;
    }
  }

  .carousel {
    .control-dots {
      .dot {
        padding: 0 !important;
        width: 15px;
        height: 15px;
        margin: 0 10px;
      }

      margin-bottom: 0;
    }

    .thumbs {
      text-align: center;
    }
  }
  .carousel.carousel-slider {
    max-height: 500px;
  }

  .slide {
    img {
      object-fit: contain;
      max-height: 500px;
    }
  }

  .contact {
    background-color: #111111;

    h3 {
      margin-bottom: 5px;
    }

    .gap {
      gap: 70px;
      padding-bottom: 25px;
    }

    .form-section {
      width: 50vw;
      margin: auto;
    }
  }
}

@media screen and (max-width: 1100px) {
  .home-page {
    .project-container {
      column-gap: 50px;
    }

    .contact {
      .form-section {
        width: 85vw;
      }
    }

    .gem {
      pointer-events: none;
    }
  }
}

@media screen and (max-width: 900px) {
  h1 {
    font-size: 28pt;
  }

  h3 {
    font-size: 16pt;
  }

  .home-page {
    .banner {
      height: 70vh !important;

      .text-container {
        h1 {
          font-size: 46pt !important;
          line-height: 65px !important;
        }
      }
    }

    .about {
      .flex {
        flex-direction: column;
      }
    }

    .project-container {
      display: block;

      .flex {
        flex-direction: row;
      }

      .project {
        margin-bottom: 0;
        padding-bottom: 60px;
      }
    }
  }

  .divider {
    width: 60vw !important;
  }

  .old-english {
    font-size: 26pt;
    letter-spacing: 1px;
  }

  .flex.gap {
    gap: 5px;
  }
}

@media screen and (max-width: 650px) {
  .home-page {
    .student {
      iframe {
        width: 90vw;
      }

      .gap {
        gap: 50px;
      }
    }

    .carousel-root {
      max-width: 90vw;
    }

    .contact {
      .form-section {
        .flex {
          display: block;
          margin: 0;
        }

        .input-container {
          padding-bottom: 25px;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  h1 {
    font-size: 24pt;
  }

  .header {
    padding: 4vh 0;
  }

  .home-page {
    .banner {
      height: 50vh !important;

      .text-container {
        h1 {
          font-size: 30pt !important;
          line-height: 45px !important;
        }
      }
    }

    .image-section {
      img {
        object-fit: cover;
        width: 90vw;
        margin-bottom: 20px;
      }
    }

    .project-container {
      .flex {
        flex-direction: column;
      }
    }
  }

  .divider {
    width: 80vw !important;
  }

  .break {
    padding: 2vh 0;
  }
}

@media screen and (max-width: 300px) {
}
