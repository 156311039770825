@import "../../styles/variables";

.xp-container {
  position: relative;
  height: 50px;

  .xp-bar {
    position: absolute;
    height: 40px;
    background: $xp-gradient-reverse;
    top: 2px;
    z-index: 99;
  }

  .red {
    background: $xp-gradient-alt !important;
  }

  .silver {
    background: $xp-gradient-silver !important;
  }

  img {
    position: absolute;
    top: 0;
    z-index: 100;
    width: 100%;
    object-fit: fill;
    height: 45px;
  }
}

@media screen and (max-width: 900px) {
  .xp-container {
    height: 55px;

    img {
      height: 50px;
      object-fit: fill;
    }

    .xp-bar {
      height: 40px;
    }
  }
}

@media screen and (max-width: 500px) {
  .xp-container {
    height: 45px;

    img {
      height: 35px;
    }

    .xp-bar {
      height: 30px;
    }
  }
}

@media screen and (max-width: 300px) {
  .xp-container {
    height: 35px;

    img {
      height: 25px;
    }

    .xp-bar {
      height: 20px;
    }
  }
}
