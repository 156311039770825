$black: #262526;
$white: #fff;
$primary: #5d7f8c;
$secondary: #86a035;
$highlight: #24e3f5;
$mute: #515932;
$gold: #cd7f32;

$xp-gradient-reverse: linear-gradient(
  90deg,
  rgba(59, 58, 20, 1) 0%,
  rgba(85, 87, 26, 1) 30%,
  rgba(128, 131, 41, 1) 60%,
  rgba(187, 179, 30, 1) 100%
);
$xp-gradient-alt: linear-gradient(
  90deg,
  rgba(114, 42, 0, 1) 0%,
  rgba(217, 106, 41, 1) 30%,
  rgba(242, 160, 6, 1) 60%,
  rgba(243, 208, 82, 1) 100%
);
$xp-gradient-silver: linear-gradient(
  90deg,
  rgba(53, 71, 85, 1) 0%,
  rgba(156, 166, 179, 1) 30%,
  rgba(172, 190, 212, 1) 60%,
  rgba(255, 255, 255, 1) 100%
);
