@import "./animation";
@import "./variables";

* {
  padding: 0;
  margin: 0;
}

.flex {
  display: flex;

  .f-1 {
    flex: 1;
  }

  .f-2 {
    flex: 2;
  }

  &.gap {
    gap: 100px;
  }

  &.column {
    flex-direction: column;
  }
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.wrap {
  flex-wrap: wrap;
}

.m-auto {
  margin: auto;
}

h1 {
  font-size: 36pt;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: $white;
}

h2 {
  font-size: 24pt;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  color: $white;
  padding-bottom: 15px;
}

h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 18pt;
  font-weight: 600;
  color: #fff;
  // border-bottom: 1px solid #fff;
  margin-bottom: 15px;
  padding-bottom: 5px;
}

h4 {
  font-family: "Montserrat", sans-serif;
  color: #fff;
  font-weight: 400;
  padding-bottom: 10px;
}

p,
li {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: $white;
  padding-bottom: 30px;
  line-height: 23px;
  text-align: justify;
}

a {
  display: block;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: $gold;
  margin-top: 15px;
}

ul {
  margin-left: 1.2em;
  text-align: left;
}

header {
  padding: 50px 0;
}

.divider {
  width: 675px;
  margin: 20px;
}

.no-padding {
  padding: 0;
}

.animation-layer {
  animation-delay: 1s;
  display: block;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  position: absolute;
  backface-visibility: hidden;
  background-position: center center !important;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform-style: preserve-3d;
  will-change: transform;
}

.embers {
  background: url("../assets/images/dragon_smaller/embers.gif");
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 999;
  opacity: 0.4;
}

.spacing {
  padding: 0 5vw;
}

.spacer {
  display: block;
  padding: 20px 0;
}

.break {
  background-color: #111111;
  padding: 5vh 0;
}

.padding-bottom {
  padding-bottom: 35px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  border: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #111111;
}

/* Handle */
::-webkit-scrollbar-thumb {
  // background: #04504c;
  background: #cd8032;
  border-radius: 10px;
  box-shadow: inset 2px 2px 2px rgba(207, 173, 140, 1),
    inset -2px -2px 2px rgba(100, 64, 27, 1);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e9a55f;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.fffont {
  font-family: "finalFantasy";
}

.fancy-text {
  font-size: 26pt;
  font-family: "Fancy_Quisley";
}

.old-english {
  font-size: 42pt;
  font-family: "OldeEnglish";
  letter-spacing: 5px;
}

.cloister-black {
  font-size: 26pt;
  font-family: "CloisterBlack";
}

.old-london {
  font-size: 26pt;
  font-family: "OldLondon";
}

@font-face {
  font-family: "finalFantasy";
  src: local("finalf"), url("../assets/font/finalf.ttf") format("truetype");
}

@font-face {
  font-family: "Fancy_Quisley";
  src: local("Fancy_Quisley"),
    url("../assets/font/Fancy_Quisley.ttf") format("truetype");
}

@font-face {
  font-family: "CloisterBlack";
  src: local("CloisterBlack"),
    url("../assets/font/CloisterBlack.ttf") format("truetype");
}

@font-face {
  font-family: "OldeEnglish";
  src: local("OldeEnglish"),
    url("../assets/font/OldeEnglish.ttf") format("truetype");
}

@font-face {
  font-family: "OldLondon";
  src: local("OldLondon"),
    url("../assets/font/OldLondon.ttf") format("truetype");
}

input,
textarea {
  width: calc(100% - 35px);
  font-size: 24pt;
  border-radius: 5px;
  border: none;
  padding: 5px 15px;
  border: 2px solid #fff;

  &.error {
    border: 2px solid #d80707;
  }
}

button {
  font-size: 18pt;
  margin: 25px 0;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: $gold;
  color: #fff;
  transition: all ease-in-out 0.25s;

  &:hover {
    background-color: #111111;
    border: 2px solid $gold;
    color: $gold;
    transition: all ease-in-out 0.25s;
  }
}
