.about-page {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: radial-gradient(circle, #181818 0%, #121212 50%, #0a0a0a 100%);

  .head-top-half {
    background: url("../../assets/images/dragon_smaller/Head_TopHalf.png");
    z-index: 2;
    width: 500px;
    height: 500px;
    animation: head-top-half-animation 3s infinite;
    animation-direction: alternate-reverse;
    animation-timing-function: ease-in-out;
    transform-origin: bottom left;
    bottom: 215px;
    left: 950px;
  }

  .head-bottom-half {
    background: url("../../assets/images/dragon_smaller/Head_BottomJaw.png");
    z-index: 3;
    width: 400px;
    height: 400px;
    bottom: 145px;
    left: 1010px;
    animation: head-bottom-half-animation 3s infinite;
    animation-direction: alternate-reverse;
    animation-timing-function: ease-in-out;
    transform-origin: top left;
  }

  .dragon-body {
    background: url("../../assets/images/dragon_burnt_forest/Body.png");
    z-index: 1;
  }
}

@keyframes head-top-half-animation {
  0% {
    transform: translateX(0) translateY(0) rotate(0deg);
  }

  100% {
    transform: translateX(0) translateY(0) rotate(-2deg);
  }
}

@keyframes head-bottom-half-animation {
  0% {
    transform: translateX(0) translateY(0) rotate(0deg);
  }

  100% {
    transform: translateX(0) translateY(-5px) rotate(2deg);
  }
}
