@keyframes head-top-half-animation {
  0% {
    transform: translateX(0) translateY(0) rotate(0deg);
  }

  100% {
    transform: translateX(0px) translateY(-9px) rotate(-1deg);
  }
}

@keyframes head-bottom-half-animation {
  0% {
    transform: translateX(0) translateY(0) rotate(0deg);
  }

  100% {
    transform: translateX(0px) translateY(-9px) rotate(0);
  }
}

@keyframes left-wing-animation {
  0% {
    transform: translateX(0) translateY(0) rotate(0deg);
  }

  100% {
    transform: translateX(0) translateY(0) rotate(-1deg);
  }
}

@keyframes right-wing-animation {
  0% {
    transform: translateX(0) translateY(0) rotate(0deg);
  }

  100% {
    transform: translateX(0) translateY(0) rotate(1deg);
  }
}

@keyframes bottom-claw-animation {
  0% {
    transform: translateX(0) translateY(0) rotate(0deg);
  }

  100% {
    transform: translateX(0) translateY(0) rotate(1deg);
  }
}

@keyframes back-leg-animation {
  0% {
    transform: translateX(0) translateY(0) rotate(0deg);
  }

  100% {
    transform: translateX(5px) translateY(15px) rotate(0.5deg);
  }
}

@keyframes landscape-animation {
  0% {
    opacity: 0;
    top: 200px;
    transform: scale(2);
  }
  100% {
    opacity: 1;
    top: 0px;
    transform: scale(1);
  }
}

@keyframes landscape-moveup-animation {
  0% {
    top: 500px;
    opacity: 0;
  }
  100% {
    top: 0px;
    opacity: 1;
  }
}

@keyframes banner-text-animation {
  0% {
    letter-spacing: 10px;
    font-size: 120pt;
    opacity: 0;
    line-height: 155px;
  }
  100% {
    letter-spacing: 0;
    font-size: 90pt;
    opacity: 1;
    line-height: 115px;
  }
}

@keyframes bird-move-accross {
  from {
    left: 0;
  }
  to {
    left: 150%;
  }
}

@keyframes bird-move-accross {
  0% {
    opacity: 0;
    left: -50px;
  }

  4% {
    opacity: 0;
  }

  5% {
    opacity: 1;
  }

  100% {
    left: 100vw;
  }
}

@keyframes bird-move-accross-behind {
  0% {
    left: -250px;
  }

  100% {
    left: 100vw;
  }
}
