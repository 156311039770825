@import "../../styles/variables";

nav {
  position: fixed;
  top: -60px;
  left: 0;
  width: 100%;
  justify-content: center;
  background-color: rgba(17, 17, 17, 0.75);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  height: 60px;
  z-index: 999;
  transition: all 0.25s ease-in-out;

  &.show {
    transition: top 0.25s ease-in-out;
    top: 0;
  }

  .nav-container {
    display: flex;
    margin: 0 25vw;
    height: 100%;

    p {
      padding: 0;
      margin: auto;
      transition: all 0.15s ease-in-out;

      &:hover {
        transition: all 0.15s ease-in-out;
        color: $gold;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  nav {
    .nav-container {
      margin: 0 5vw;
    }
  }
}

@media screen and (max-width: 500px) {
  nav {
    .nav-container {
      margin: 0 2vw;

      p {
        font-size: 8pt;
      }
    }
  }
}
